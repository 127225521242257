<template>
  <div class="banners"
       style="background: #f6f6f6;padding-bottom: 300px;">
    <div class="wx_pay_box">
      <div>
        <img src="../../assets/images/wxewm_03.png"
             alt="">
      </div>
      <div class="head">
        <div>订单编号：{{ wxInfo.orderNo }}</div>
        <div>应付金额：<span style="color: #EF1300;font-size: 25px;">￥{{ wxInfo.money }}</span> 元</div>
      </div>

      <div class="wx_pay_content">
        <div>
          <img src="../../assets/images/wxewm_07.png"
               alt="">
        </div>

        <div class="ss">
          <div>
            <div>
              <img style="width: 274px;height: 274px;"
                   :src="wxInfo.codeUrl"
                   alt="">
            </div>
            <div style="margin-top: 15px;">
              <img src="../../assets/images/wxewm_16.png"
                   alt="">
            </div>
          </div>
          <div style="margin-left: 80px;">
            <img src="../../assets/images/wxewm_13.png"
                 alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { toTradeQueryWX, toTradeQueryWX2, getExpressDetail, getOrderPayInfo } from '@/api/pay.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      wxInfo: {},
      timer: null
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    toTradeQueryWX2() {
      toTradeQueryWX2({ expressId: this.wxInfo.orderId }).then(res => {
        console.log(res.data.data.result);
        if (res.data.data.result == '1') {
          this.$router.replace({
            path: '/patYundan',
            query: {
              orderId: this.wxInfo.orderId
            }
          });
        }
      });
    },
    toTradeQueryWX() {
      toTradeQueryWX({ orderId: this.wxInfo.orderId }).then(res => {
        console.log(res.data.data.result);
        if (res.data.data.result == '1') {
          this.$router.replace({
            path: '/paySuccess',
            query: {
              orderId: this.wxInfo.orderId
            }
          });
        }
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.$route.query.yd == '1') {
      getExpressDetail({ expressId: this.$route.query.expressId }).then(res => {
        console.log(res.data.data);
        this.wxInfo = {
          codeUrl: this.$route.query.codeUrl,
          orderNo: res.data.data.expressNo,
          orderId: res.data.data.id,
          money: res.data.data.expressPrice,
          yd: 1
        };
      });
    } else {
      getOrderPayInfo({ orderId: this.$route.query.orderId }).then(res => {
        console.log(res.data);
        this.wxInfo = {
          codeUrl: this.$route.query.codeUrl,
          orderNo: res.data.data.order.ORD_NO,
          orderId: this.$route.query.orderId,
          money: res.data.data.order.ORD_MONEYSUM
        };
      });
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    if (this.$route.query.yd && this.$route.query.yd == '1') {
      this.timer = setInterval(() => {
        this.toTradeQueryWX2();
      }, 2000);
    } else {
      this.timer = setInterval(() => {
        this.toTradeQueryWX();
      }, 2000);
    }
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  destroyed() {
    clearInterval(this.timer);
  }
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.banners {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  font-size: 19px;
  font-family: Regular;
  background: #f6f6f6;
  .wx_pay_box {
    width: 1024px;
    .head {
      margin-top: 45px;
      display: flex;
      justify-content: space-between;
    }
    .wx_pay_content {
      margin-top: 25px;
      background: #fff;
      width: 1024px;
      height: 596px;
      padding: 20px 0 0 50px;
      .ss {
        display: flex;
        padding: 40px 545px 123px 155px;
      }
    }
  }
}
</style>